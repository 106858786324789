import React from 'react';
import appleDownload from '../../assets/img/appledownload.svg';
import playStoreDownload from '../../assets/img/googledownload.png';
import hero from '../../assets/img/hero.png';
const Hero = () => {
    return <div >
        
        
            <img className='w-full h-screen object-cover '  src={hero} />
            <div className='text-white absolute top-40 md:w-1/2 flex space-y-6 flex-col p-6 justify-center items-center'>
               <p className='text-6xl font-bold'>Rejoignez nous.</p>
               <p className='text-3xl text-white'>samopil est nouveu!
                Rejoignez nous et faites parti d'une nouvelle ere dans le developpement .
                
               </p>
              
            </div>
        </div>
};

export default Hero;