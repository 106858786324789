import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/logo.png'
import MenuIcon from '@mui/icons-material/Menu';

const Header = () => {
    return <div className='flex px-8 absolute top-0 pb-4 bg-transparent w-full z-30  pt-2 items-center justify-between'>
    <p >
    <Link to="/">
        <img className='w-24 hover:cursor-pointer' src={logo} /> 
      </Link>
    </p>
    <div className='hidden text-sm uppercase text-white  md:flex items-center space-x-2'>
        <Link to="/">
        <p className='hover:cursor-pointer'>Accueil</p>
        </Link>
        <Link to="/help">
        <p className='hover:cursor-pointer'>Aide</p>
        </Link>
        <Link to="/contact">
        
        <p className='hover:cursor-pointer'>Contact</p>
        </Link>
    </div>

    <div className='md:hidden flex text-white'>
        <MenuIcon />
    </div>
    </div>
};

export default Header;