import React from 'react';
import Header from '../Header/Header';
import Hero from '../Hero/Hero';
import Footer from '../Footer/Footer';

import Restaurateur from '../Restaurateur/Restaurateur';
import Consommateur from '../Consommateur/Consommateur';
import Presentation from '../Presentation/Presentation';

const Home = () => {
    return <div className='my-0'>
        <Header />
        <Hero />
        <Presentation />
        <Footer />
        
    </div>
};

export default Home;