import { Link } from "react-router-dom";
import logo from '../../assets/img/logo.png';
import MenuIcon from '@mui/icons-material/Menu';

const Cgv  = () => {


    return <div className="">
        <div className='flex px-8 absolute top-0 pb-4 bg-transparent w-full  z-30  pt-2 items-center justify-between'>
    <p >
    <Link to="/">
        <img className='w-24 hover:cursor-pointer' src={logo} /> 
      </Link>
    </p>
    <div className='hidden text-sm uppercase text-black  md:flex items-center space-x-2'>
        <Link to="/">
        <p className='hover:cursor-pointer'>Accueil</p>
        </Link>
        <Link to="/help">
        <p className='hover:cursor-pointer'>Aide</p>
        </Link>
        <Link to="/contact">
        
        <p className='hover:cursor-pointer'>Contact</p>
        </Link>
    </div>

    <div className='md:hidden flex text-black'>
        <MenuIcon />
    </div>
    </div>

    <div className="mt-28">
    <div className="w-4/5 mx-auto mt-10"> 
  
      <p className="text-3xl font-bold mb-6">
        Conditions générales de vente
      </p>
      <p className="mb-6 ">
        Date de Mise à jour : 04/02/2024
      </p>
  
      <p className="mb-6">
      La plateforme « samopil » propose, via son site internet et deux applications,
      la mise en relation entre des clients, personnes morales ou physiques
      souhaitant commander des repas (ci-après collectivement « les Clients » et individuellement « le Client »),
      et des restaurateurs (ci-après collectivement « les Commerçants » et individuellement « le Commerçant »).
      Les repas commandés pourront être offerts par le Client à un tiers personne physique (ci-après “Tiers Bénéficiaire”).
      </p>
  
      <p className="mb-6">
        <span className="font-bold">A chaque fois qu’un Client passe une commande, il conclut un contrat de vente avec le Commerçant auprès duquel il a passé sa commande.</span>
         Tous les contrats de vente conclus par l’intermédiaire de la plateforme sont soumis aux présentes conditions générales de vente (ci-après les « CGV »). Les présentes Conditions Générales de Vente s’appliquent entre le Client et Commerçant concerné relativement aux produits commandés par le Client. 
  
      </p>
  
  
      <p className="mb-6">
        Attention : 
      </p>
  
  
  
      <p className="mb-6">
        TOUT CONTRAT DE VENTE ENTRE LE COMMERÇANT ET LE CLIENT EXIGE DE CE DERNIER QU’IL AIT PREALABLEMENT PRIS CONNAISSANCE DANS LEUR INTEGRALITE ET ACCEPTE SANS RESERVE LES PRESENTES CONDITIONS GENERALES DE VENTE.  
  
      </p>
  
  
      <p className="mb-6">
        POUR TOUT CONTRAT DE VENTE, LE CLIENT GARANTIT :
  
      </p>
  
  
  
      <p className="mb-6">
        -	ÊTRE UNE PERSONNE MORALE OU UNE PERSONNE PHYSIQUE AGÉE D'AU MOINS DIX HUIT ANS, DISPOSANT DE LA PLEINE CAPACITE JURIDIQUE A CONCLURE LES PRESENTES CGV ET LE CONTRAT DE VENTE ;
      </p>
  
  
      <p className="mb-6">
        -	EXPRIMER SON ACCORD SUR L’INTEGRALITE DU CONTENU DES PRESENTES CGV ET S’OBLIGE A L’EGARD DU COMMERÇANT A EN RESPECTER L’ENSEMBLE DES TERMES.
      </p>
  
  
      <p className="mb-6">
        Les CGV applicables à une Commande sont la version en vigueur au moment où le Client a conclu le Contrat de Vente avec le Commerçant. 
      </p>
  
  
      <p className="mb-6">
        Le Client peut sauvegarder ou imprimer les présentes CGV, à condition de ne pas les modifier.
      </p>
  
  
      <p className="mb-6 font-bold">
        Ces CGV sont proposées en langue française et sont accessibles à tout moment en cliquant sur la rubrique « CGV » en bas de la page d’accueil du Site. 
      </p>
  
  
      <p className="mb-6 font-bold">
        1.	Définitions 
      </p>
  
      <p className="mb-6">
  
        •	Les termes <span className="font-bold">« Click & Collect »</span> désignent le fait pour le Tiers Bénéficiaire de récupérer la Commande chez le Commerçant pendant ses horaires d’ouverture et à un créneau déterminé. 
      </p>
  
      <p className="mb-6">
        •	Le terme <span className="font-bold"> « Client » </span>désigne la personne qui accepte les présentes Conditions Générales de Vente et qui conclut des Contrats de Vente avec le Commerçant. Le Client est une personne physique ou morale utilisant le Site dans le cadre d’un usage strictement personnel ou professionnel, mais non-commercial. 
      </p>
  
      <p className="mb-6">
        •	Le terme <span className="font-bold"> « Commande »  </span> désigne tout achat par un Client d’un ou plusieurs Produits à un Commerçant via le Site.
  
      </p>
  
      <p className="mb-6">
        •	Le terme <span className="font-bold">« Commerçants » </span>désigne tous les commerçants qui proposent aux Clients leurs Produits.
      </p>
  
      <p className="mb-6">
        •	Le terme <span className="font-bold">« Commerçant »</span> désigne le cocontractant du Client en cas de Commande dans le cadre du Contrat de Vente. L’identité du Commerçant dépend de chaque Commande du Client. Les informations sur l’identité du Commerçant sont accessibles dans sa Fiche Commerçant. 
      </p>
  
      <p className="mb-6">
        •	Les termes <span className="font-bold">« Contrat de Vente »</span> désignent le contrat conclu entre le Commerçant et le Client à compter de l’acceptation par le Commerçant de la Commande passée par le Client. Les présentes CGV sont applicables à tous les Contrats de Vente quelle que soit l’identité du Commerçant. 
      </p>
  
      <p className="mb-6">
        •	Les termes <span className="font-bold">« Espace Client »</span> désignent la partie privée du Site, à laquelle accède le Client notamment après avoir entré son identifiant et son mot de passe et lui permettant de passer Commande et d’être informé sur l’avancée de sa Commande. 
      </p>
  
      <p className="mb-6">
        •	Les termes <span className="font-bold"> « Fiche Commerçant »</span> désignent la zone du Site dans laquelle figurent l’identification du Commerçant, incluant le nom, le numéro individuel d'identification TVA intracommunautaire, l’adresse, le pays et la spécialité. La Fiche Commerçant permet notamment d’informer le Client sur l’identité de son cocontractant dans le cadre du Contrat de Vente. 
      </p>
  
      <p className="mb-6">
  
        •	Les termes <span className="font-bold">« Fiche Produit »</span> désignent la zone du Site dans laquelle figurent la description des Produits, notamment leur nom, leur prix, la description de leurs caractéristiques essentielles, une éventuelle photographie, les différents ingrédients composant le Produit ainsi que ses éventuels allergènes.
  
      </p>
  
      <p className="mb-6">
    ·	sam SARL au capital de 100 euros,<br></br>
    ·	Dont le siège social est situé au 3 RUE ANDRE CITROEN, 95130 FRANCONVILLE,<br></br>
    ·	Dont le numéro d’enregistrement au RCS de PONTOISE  est 845 288 356<br></br>
    ·	Dont le numéro de TVA est le FR94845288356.<br></br>

  </p>
  
      <p className="mb-6">
        •	Le terme <span className="font-bold">« Produit »</span> ou <span className="font-bold">« Produits »</span> désigne les repas qui sont proposés par le Commerçant via le Site et fournis par ce dernier en cas de Commande. 
      </p>
  
  
      <p className="mb-6">
        •	Le terme<span className="font-bold"> « Site »</span> désigne le site disponible à l’URL : www.samopil.com sur lequel les Produits peuvent être commandés par le Client ou les applications disponibles sous le nom samopil incluant leur partie publique, ainsi que leurs espaces privés, notamment l’Espace Client. 
      </p>
  
  
      <p className="mb-6">
        •	Les termes <span className="font-bold">« Tiers Bénéficiaire » </span>désigne la personne physique qui se rendra auprès du Commerçant pour recevoir la Commande.
        
      </p>
  
  
      <p className="mb-6 font-bold">
        2.	Objet et Durée des CGV 
      </p>
  
      <p className="mb-6">
        Les présentes Conditions Générales de Vente déterminent les conditions applicables au Contrat de Vente conclu entre le Client et le Commerçant et de manière générale à toute Commande passée sur le Site. Les présentes CGV sont applicables pour la durée nécessaire à la réalisation de son objet, c’est-à-dire pour la durée d’exécution de la Commande effectuée par le Client sur le Site. 
        
      </p>
      <p className="mb-6">
        Dans le cadre de la création de son Espace Client, le Client s’engage à accepter les présentes CGV. 
        
      </p>
      <p className="mb-6">
        En cas de modification des présentes CGV, il conviendra pour le Client d’accepter les nouvelles CGV en vigueur dès sa prochaine Commande. 
        
      </p>
  
      <p className="mb-6 font-bold">
        3.	Application des CGV au Contrat de Vente
  
      </p>
  
      <p className="mb-6">
        Lorsque le Client passe une Commande sur le Site et conclut un Contrat de Vente avec le Commerçant concerné, il accepte expressément que ce Contrat de Vente est soumis aux présentes Conditions Générales de Vente et qu’en ce sens, il est redevable à l’égard du Commerçant de l’ensemble des obligations prévues dans le présent Contrat. 
      </p>
  
      <p className="mb-6">
        A toutes fins utiles, il est précisé qu’il est conclu autant de Contrats de Vente qu’il existe de Commandes initiées par le Client et acceptées par le Commerçant.
      </p>
  
      <p className="mb-6">
        Le Client reconnaît et accepte que la Plateforme n’est pas responsable de l’intégralité des obligations prévues dans les présentes CGV qui incombent spécifiquement au Commerçant. A ce titre, le Client s’engage à contacter directement le Commerçant dans le cas où un problème survient à la suite d’une Commande. 
      </p>
  
      <p className="mb-6 font-bold">
        4.	Produits
      </p>
  
      <p className="mb-6 font-bold">
        4.1 Présentation et description des Produits
      </p>
  
      <p className="mb-6">
        4.1.1 Qualités et Fiche Produit
      </p>
  
      <p className="mb-6">
        Les différents Produits offerts à la commercialisation sur le Site par les Commerçants sont présentés par l’intermédiaire d’une Fiche Produit. 
      </p>
  
      <p className="mb-6">
        Afin de s’assurer de la compatibilité des Produits à ses attentes, notamment en fonction de ses éventuelles restrictions alimentaires ou allergies, le Client s’engage à se référer à la Fiche Produit. 
      </p>
  
      <p className="mb-6">
        Cependant, bien que la Plateforme prête une grande attention à la rédaction des Fiches Produits, il est possible que les caractéristiques perçues par le Client ne correspondent pas avec exactitude au Produit en lui-même, ce que le Client admet et reconnaît. Une éventuelle différence de perception par le Client de la forme, des couleurs et de la qualité des Produits vis-à-vis de leur description dans la Fiche Produit sur le Site au moment de la Commande ne peut engager la responsabilité de la Plateforme.   
      </p>
  
      <p className="mb-6">
        4.1.2 Vente d’alcool
      </p>
  
      <p className="mb-6">
        La vente de Produits contenant un ou des produit(s) proposé(s) dans les catégories Vin, Champagne et Spiritueux est exclusivement réservée aux personnes ayant 18 ans révolus. Dans le cas de l’achat d’un produit appartenant à l’une de ces catégories, le Client s’engage à offrir la Commande à un Tiers Bénéficiaire ayant 18 ans révolus qui devra présenter une pièce d’identité comportant sa date de naissance lors du retrait de la Commande auprès du Commerçant. La Plateforme n’engage pas sa responsabilité si le Client et/ou le Tiers Bénéficiaire n’ont pas 18 ans révolus. Le Commerçant se réserve le droit de refuser la fourniture des Produits commandés à toute personne ne justifiant pas avoir plus de 18 ans révolus. 
      </p>
  
      <p className="mb-6 font-bold">
        L'abus d'alcool est dangereux pour la sante. A consommer avec modération.
      </p>
  
      <p className="mb-6 font-bold">
        4.2 Disponibilité des Produits
      </p>
  
      <p className="mb-6">
        En principe, les offres de Produits et les prix correspondants sont valables, dans la limite des stocks disponibles, tant qu’ils sont visibles sur le Site.
      </p>
  
      <p className="mb-6">
        Cependant, le Client reconnaît et accepte que le Commerçant peut connaître (i) des ruptures de stock momentanées, (ii) une fermeture exceptionnelle de son commerce et/ou (iii) une indisponibilité de la quantité de Produits disponibles, qui n’auraient pas été indiquées sur le Site et qui entraîneraient l’annulation totale ou partielle de la Commande. Dans ce cas, ni la responsabilité du Commerçant ni celle de la Plateforme ne pourront être engagées, ce que le Client reconnaît et accepte. 
      </p>
  
      <p className="mb-6">
        Dans tous les cas, le commercant fournira les efforts raisonnables pour informer le Client sur son Espace Client et/ou par email dans les plus brefs délais, et au plus tard dans un délai d’une heure à compter de sa Commande, de l’acceptation ou de l’annulation partielle ou totale de sa Commande. Ce délai est applicable uniquement pendant les horaires d’ouverture du Commerçant. 
      </p>
  
      <p className="mb-6">
        4.3 Prix des Produits
      </p>
  
      <p className="mb-6">
        Les prix sont indiqués sur le Site pour chaque Produit en Euros Toutes Taxes Comprises pour la France. La Taxe sur la Valeur Ajoutée est celle en vigueur sur le territoire français.
      </p>
  
      <p className="mb-6">
        La Plateforme se réserve la possibilité de modifier, à la demande des Commerçants, les prix indiqués à tout moment, sans pour autant que ces modifications n’aient d’incidence sur les Commandes que le Commerçant aurait acceptées avant l’entrée en vigueur de ces modifications, ce que le Client accepte et reconnaît. 
      </p>
  
      <p className="mb-6 font-bold">
        5. Commandes et paiement
      </p>
  
      <p className="mb-6">
        Le Site est librement accessible. En revanche, toute Commande nécessite la création par le Client d’un Espace Client, telle que prévue dans les Conditions Générales d’Utilisation du Site disponible  <Link to="/cgu"><span className="font-bold cursor-pointer">ici</span></Link>
      </p>
  
  
  
      <p className="mb-6">
        Le Client, qui souhaite passer commande, choisit le ou les différents Produits de son choix en cliquant sur «Ajouter». 
      </p>
  
      <p className="mb-6">
        À tout moment le Client peut obtenir un récapitulatif du ou des Produits qu’il a sélectionnés ou modifier sa Commande, en cliquant sur l’icône « Panier », poursuivre sa sélection de Produits en cliquant sur le bouton « Continuer mes achats », commander son ou ses Produits en cliquant sur « Commander ».<br></br>
        <span className="font-bold">
         Ainsi avant de valider définitivement le détail de sa Commande, le Client a la possibilité de vérifier le détail des Produits commandés et de corriger d’éventuelles erreurs. 
  
        </span>
      </p>
  
      <p className="mb-6 font-bold">
        Pour finaliser sa Commande, le Client est invité à cliquer à nouveau sur « Commander », en application du principe de double-click. La Commande sera validée une fois le paiement réalisé et confirmé, et la Commande acceptée par le Commerçant.  
        
      </p>
  
      <p className="mb-6">
       <span className="font-bold"> La validation de la Commande vaut obligation de paiement du prix.</span> Cette validation vaut également acceptation des caractéristiques et quantités des Produits commandés, des modalités et délais de mise à dispositions des Produits, ainsi que de toutes restrictions ou conditions particulières stipulées aux présentes. 
        
      </p>
  
      <p className="mb-6 font-bold">
        Le paiement se fera en euros exclusivement par carte bancaire (CB, Visa, Mastercard) sur le Site. 
      </p>
  
      <p className="mb-6">
        Afin de procéder au paiement dû, le Client devra fournir son numéro de carte bancaire, la date d’expiration de celle-ci accompagnée, le cas échéant, du cryptogramme visuel. Aucune donnée concernant les moyens de paiement n’est conservée par la Plateforme. En conséquence, le Client doit les saisir à chaque nouvelle Commande. 
      </p>
  
      <p className="mb-6">
        Le Client garantit qu’il dispose des autorisations nécessaires pour utiliser l’instrument de paiement choisi au moment de la validation de sa Commande.
      </p>
  
      <p className="mb-6">
        En cas de refus du paiement, la Commande sera automatiquement annulée et le Client en sera informé par la Plateforme sur son Espace Client. 
      </p>
  
      <p className="mb-6 font-bold">
        Si le paiement est accepté et la Commande acceptée par le Commerçant, le Client sera informé par email de la confirmation de sa Commande et d’un récapitulatif de cette dernière. Cet email de confirmation sera accompagné d’une version PDF des CGV applicables. 
      </p>
  
      <p className="mb-6 font-bold">
        6. Click & Collect
      </p>
  
      <p className="mb-6">
        Le Client est libre de passer Commande auprès de n’importe quel Commerçant, étant précisé que le Client s’engage à s’assurer que le Tiers Bénéficiaire est en mesure de venir récupérer sa Commande pendant le créneau qu’il aura sélectionné. 
      </p>
  
      <p className="mb-6">
        Le Client est informé et accepte que le respect du créneau sélectionné est impératif, c’est-à-dire que le Tiers Bénéficiaire doit se rendre chez le Commerçant au créneau sélectionné. 
        
      </p>
  
      <p className="mb-6">
        En cas d’impossibilité de fournir les Produits, la Commande sera annulée et les sommes payées seront remboursées au Client. 
      </p>
  
      <p className="mb-6 font-bold">
        7. Réception de la Commande
      </p>
  
      <p className="mb-6">
        A la réception de la Commande, le Client s’assure que le Tiers Bénéficiaire vérifie la conformité des Produits reçus en exécution de sa Commande. Le Commerçant rappelle qu’au moment où le Tiers Bénéficiaire prend possession physiquement des Produits, le risque de perte ou d’endommagement des Produits est transféré au Client. 
      </p>
  
      <p className="mb-6 font-bold">
        8. Absence de droit de rétractation 
      </p>
  
      <p className="mb-6 font-bold">
        Le Client est informé qu’en application de l'article L.221-28 4° du Code de la Consommation, le droit de rétractation ne peut être exercé pour la fourniture de Produits susceptibles de se détériorer ou de se périmer rapidement.
      </p>
  
      <p className="mb-6">
        En conséquence le Client est informé qu’il ne pourra faire valoir son droit de rétractation contractuel sur aucune Commande, puisque l’intégralité des Produits proposés par les Commerçants ont des conditions de conservation ne permettant pas une nouvelle commercialisation. 
      </p>
  
      <p className="mb-6 font-bold">
        9. Garanties – Conformité
      </p>
  
      <p className="mb-6">
        Tous les Produits proposés à la vente sur le Site bénéficient de la garantie légale de conformité (telle que définie aux articles L.217-4 du Code de la consommation) et de la garantie contre les vices cachés (telle que définie aux articles 1641 et suivants du Code civil), permettant au Client de retourner sans frais tous les Produits défectueux ou non conformes. 
      </p>
  
      <p className="mb-6">
        Le Commerçant est responsable de ces garanties légales, Il est également tenu de fournir un Produit conforme à la Commande et répond des défauts de conformité existant lors de la délivrance.
  
      </p>
  
      <p className="mb-6">
        Le Client doit s’assurer que les Produits fournis au Tiers Bénéficiaire correspondent à sa Commande.
  
      </p>
  
      <p className="mb-6">
        Dans le cadre de la garantie légale de conformité, le Commerçant s’engage au choix du Client :
  
      </p>
  
      <p className="mb-6">
        – soit à remplacer le Produit par un autre produit en fonction des stocks disponibles,
  
      </p>
  
      <p className="mb-6">
        – soit à rembourser le prix du Produit, dans un délai maximum de 30 jours, si le remplacement s’avérait impossible.
  
      </p>
  
      <p className="mb-6">
        Pour toute demande concernant les garanties, le Client doit contacter directement le Commerçant concerné aux coordonnées qui sont indiquées dans la Fiche Commerçant.  
  
      </p>
  
      <p className="mb-6 font-bold">
        9.1 Garantie légale de conformité 
      </p>
  
      <p className="mb-6">
        Le Code de la consommation prévoit ce qui suit en termes de garantie légale de conformité : 
      </p>
  
      <p className="mb-6">
        Article L217-4 : « Le vendeur livre un bien conforme au contrat et répond des défauts de conformité existant lors de la délivrance.
      </p>
  
      <p className="mb-6">
        Il répond également des défauts de conformité résultant de l'emballage, des instructions de montage ou de l'installation lorsque celle-ci a été mise à sa charge par le contrat ou a été réalisée sous sa responsabilité. »
      </p>
  
  
      <p className="mb-6">
        Article L217-5 : 
      </p>
  
  
      <p className="mb-6">
        « Le bien est conforme au contrat :
      </p>
  
  
      <p className="mb-6">
        1° S'il est propre à l'usage habituellement attendu d'un bien semblable et, le cas échéant :
      </p>
  
  
      <p className="mb-6">
        o	s'il correspond à la description donnée par le vendeur et possède les qualités que celui-ci a présentées à l'acheteur sous forme d'échantillon ou de modèle ;
      </p>
  
  
      <p className="mb-6">
        o	s'il présente les qualités qu'un acheteur peut légitimement attendre eu égard aux déclarations publiques faites par le vendeur, par le producteur ou par son représentant, notamment dans la publicité ou l'étiquetage ;
      </p>
  
  
      <p className="mb-6">
        2° Ou s'il présente les caractéristiques définies d'un commun accord par les parties ou est propre à tout usage spécial recherché par l'acheteur, porté à la connaissance du vendeur et que ce dernier a accepté. »
      </p>
  
  
      <p className="mb-6">
        « Article L.217-12 : L'action résultant du défaut de conformité se prescrit par deux ans à compter de la délivrance du bien. »
      </p>
  
      <p className="mb-6 font-bold">
        9.2 Garantie contre les vices cachés
      </p>
  
      <p className="mb-6">
        Le code civil français prévoit ce qui suit en termes de garantie des vices cachés :
      </p>
  
      <p className="mb-6">
        Article 1641 du Code Civil : « Le vendeur est tenu de la garantie à raison des défauts cachés de la chose vendue qui la rendent impropre à l'usage auquel on la destine, ou qui diminuent tellement cet usage que l'acheteur ne l'aurait pas acquise, ou n'en aurait donné qu'un moindre prix, s'il les avait connus. »
      </p>
  
      <p className="mb-6">
        Article 1648 du Code Civil : « L'action résultant des vices rédhibitoires doit être intentée par l'acquéreur dans un délai de deux ans à compter de la découverte du vice. »
      </p>
  
      <p className="mb-6 font-bold">
        9.3 Garanties supplémentaires
      </p>
  
      <p className="mb-6">
        Le Commerçant s’engage à :
      </p>
  
      <p className="mb-6">
        -	assurer une conservation permettant le respect de la chaîne du froid de tous les Produits jusqu’à leur remise en Click & Collect ;
      </p>
  
      <p className="mb-6">
        -	respecter les normes sanitaires applicables à ses Produits ;
      </p>
  
  
      <p className="mb-6">
        -	vérifier la date de naissance du Client et à ne pas accepter la Commande dans le cas où le Client n’aurait pas 18 ans révolus. 
      </p>
  
      <p className="mb-6 font-bold">
        10. Données personnelles
      </p>
  
      <p className="mb-6">
        Les données personnelles collectées par la Plateforme dans le cadre de la passation d’une Commande, sont destinées à la Plateforme et au Commerçant et sont utilisées aux fins d’exécuter la Commande passée.
      </p>
  
      <p className="mb-6">
        Le Client dispose d’un droit d’accès, de modification, de rectification et de suppression des données le concernant. Le Client dispose également du droit de s’opposer, pour motifs légitimes, à ce que ses données fassent l’objet d’un traitement.
      </p>
  
      <p className="mb-6">
        Pour exercer ces droits, le Client peut écrire à l’adresse suivante : sam 3 RUE ANDRE CITROEN, 95130 FRANCONVILLE
      </p>
  
      <p className="mb-6">
        Pour plus d’information sur le traitement de ses données personnelles, le Client est invité à consulter la Politique de confidentialité du Site en cliquant <span className="font-bold">ici.</span>
      </p>
  
      <p className="mb-6 font-bold">
        11. Litige
      </p>
  
      <p className="mb-6">
        Les présentes CGV sont soumises au droit français.
      </p>
  
      <p className="mb-6">
        Toutes contestations qui pourraient naître sur l’exécution et l’interprétation des présentes conditions générales devront être portées devant les tribunaux compétents.
      </p>
  
      <p className="mb-6 font-bold">
        Par ailleurs, conformément à l’article L.612-1, le Client « a le droit de recourir gratuitement à un médiateur de la consommation en vue de la résolution amiable du litige qui l'oppose à un professionnel. A cet effet, le professionnel garantit au consommateur le recours effectif à un dispositif de médiation de la consommation. »
      </p>
  
      <p className="mb-6 font-bold">
        En cas de litige avec le Commerçant, le Client peut contacter le médiateur qui lui sera indiqué par le Commerçant. 
      </p>
  
      <p className="mb-6 font-bold">
        12. Force majeure
      </p>
  
      <p className="mb-6">
        Le Commerçant et la Plateforme ne seront pas responsables pour tout manquement à leurs obligations contractuelles dans l’hypothèse d’un cas de force majeure tel que défini par le Code civil et la jurisprudence ou dans le cas où ce manquement est imputable soit au Client, soit au fait, imprévisible et insurmontable, d'un tiers au contrat. 
      </p>
  
    </div>

  </div>
    </div>

}

export default Cgv;