import { Link } from "react-router-dom";
import logo from '../../assets/img/logo.png';
import MenuIcon from '@mui/icons-material/Menu';
import { RightOutlined } from "@ant-design/icons";
import Footer from "../Footer/Footer";
const Help =  () => {


    return <div className="flex flex-col">
         <div className='flex px-8 pb-4 bg-transparent z-30  pt-2 items-center justify-between'>
    <p >
    <Link to="/">
        <img className='w-24 hover:cursor-pointer' src={logo} /> 
      </Link>
    </p>
    <div className='hidden text-sm uppercase font-semibold text-black  md:flex items-center space-x-2'>
        <Link to="/">
        <p className='hover:cursor-pointer'>Accueil</p>
        </Link>
        <Link to="/help">
        <p className='hover:cursor-pointer'>Aide</p>
        </Link>
        <Link to="/contact">
        
        <p className='hover:cursor-pointer'>Contact</p>
        </Link>
    </div>

    <div className='md:hidden flex text-black'>
        <MenuIcon />
    </div>
    </div>


    <div className="grow py-40">
    <div className="mt-32">
        <p className="text-center font-semibold text-3xl">Aide</p>
    </div>

    <div className="flex  items-center py-6 justify-center mt-10 flex-col space-y-4">
        <p className="text-lg flex hover:cursor-pointer items-center space-x-10">
            <span>Nous contacter</span>
            <RightOutlined className="text-gray-500" />
        </p>

    </div>
    </div>

    <Footer />
    </div>
}

export default Help;