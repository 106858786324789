import React from 'react';
import tiktok from '../../assets/img/tiktok.png';
import instagram from '../../assets/img/instagram.png';
import { Link } from 'react-router-dom';

const Footer = () => {
    return <div className='bg-black p-6 text-white font-Raleway flex flex-col space-y-2'>
        <div>
            <p className='text-white  font-extrabold text-2xl text-center'>samopil</p>
        </div>
        <div className='flex items-center justify-center'>
            <p>
                <img className='w-20 hover:cursor-pointer' src={tiktok} />
            </p>
            <p>
                <img className='w-10 hover:cursor-pointer' src={instagram} />
            </p>
        </div>
        <div className='flex flex-col md:flex-row items-center justify-center space-x-4  font-thin '> 
        <Link to="/cgu">
            <p className='hover:cursor-pointer'>Conditions générales d'utilisation</p>
        </Link>
        <Link to="/cgv">
        <p className='hover:cursor-pointer'>Conditions générales de vente</p>
        </Link>
        <Link to="/privacy">
        <p className='hover:cursor-pointer'>Politique de confidentialités</p>
        </Link>

        </div>

        <div className='flex items-center justify-center space-x-2 font-thin '> 
        <Link to="/help">
        <p className='hover:cursor-pointer'>AIDE</p>
        </Link>
        <Link to="/contact">
        <p className='hover:cursor-pointer'>CONTACT</p>
        </Link>

        </div>
        
        
        
        
    </div>
};

export default Footer;